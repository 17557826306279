<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="allCounts">
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-2xl ">Stock Count</h3>
        </div>
        <div class="flex-col">
          <div class="flex flex-row gap-5">
            <trac-button class="uppercase" disabled
              >Count with barcode scanner</trac-button
            >
            <trac-button
              @button-clicked="$router.push({ name: 'NewCount' })"
              class="uppercase"
              >count with camera</trac-button
            >
          </div>
        </div>
      </div>
      <div
        class="px-8 py-8  bg-white rounded-lg h-full  overflow-hidden big-shadow"
      >
        <div class="border rounded-lg overflow-auto">
          <table class="rounded-lg w-full h-full table-auto overflow-auto">
            <thead class="bg-blue-100">
              <tr>
                <th class="text-left p-4 text-xs font-semibold uppercase">
                  Date
                </th>
                <th class="text-left p-4 text-xs font-semibold uppercase">
                  Count Name
                </th>
                <th class="text-left p-4 text-xs font-semibold uppercase">
                  Count Status
                </th>
                <th class="text-left p-4 text-xs font-semibold uppercase">
                  No. of products
                </th>
                <th class="text-left p-4 text-xs font-semibold uppercase">
                  Store
                </th>
              </tr>
            </thead>
            <tbody v-if="allCounts.length > 0">
              <tr
                :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                v-for="(count, index) in allCounts"
                class="cursor-pointer hover:bg-gray-400"
                :key="index"
                @click="gotoStockCountDetailScreen(count)"
              >
                <td class=" p-4">
                  <span class="text-xs">{{
                    moment(count.date).format("DD/MM/YYYY")
                  }}</span>
                  <br />
                  <span class="text-xs text-primaryGray">{{
                    moment(count.date).format("h:mm A")
                  }}</span>
                </td>
                <td class="text-xs p-4 font-bold capitalize">
                  {{ count.name }}
                </td>
                <td :style="{color: count.paused ? '#FFA200' : checkCountCompletion(count.Products) ? '#E30000' : '#2B7700'}">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      :stroke="count.paused ? '#FFA200' : checkCountCompletion(count.Products) ? '#E30000' : '#2B7700'"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="8" x2="12" y2="12" />
                      <line x1="12" y1="16" x2="12.01" y2="16" />
                    </svg>
                    <span class="pl-2 text-xs">
                    {{ count.paused ? 'Count Paused' : checkCountCompletion(count.Products) ? unresolvedCounts(count.Products).length > 0 ? unresolvedCounts(count.Products).length + ' Unresolved issue(s)' : 'Count Completed' : 'Count Completed' }}
                    </span>
                  </div>
                </td>
                <td class="p-4 text-xs capitalize">
                  {{ count.Products.length }} Product(s)
                </td>
                <td class="p-4 text-xs capitalize">
                  {{ count.location }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      No count added yet.
                    </div>
                  </trac-center-data>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import moment from "moment";
import { DELETE_LOCAL_DB_DATA, GET_LOCAL_DB_DATA, SAVE_LOCAL_DB_DATA } from '../../../browser-db-config/localStorage';
import { eventBus } from "./../../../main";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      allCounts: null,
    };
  },
  async created() {
    this.isLoading = true;
    DELETE_LOCAL_DB_DATA('stock-count-data');
    DELETE_LOCAL_DB_DATA('stock-count-details');
    await this.fetchAllStockCount();
    this.isLoading = false;
  },
  methods: {
    checkCountCompletion(products) {
      return products.find(
        (prod) => prod.counted !== prod.expected && prod.reason.length === 0
      );
    },
    gotoStockCountDetailScreen(count) {
      if (count.paused) {
        SAVE_LOCAL_DB_DATA('stock-count-data', { saved_counted_details: count, ...count.countData, from: 'All Counts' });
          this.$router.push({ name: 'SubmitCount' });
        } else {
        SAVE_LOCAL_DB_DATA('stock-count-details', count);
        this.$router.push({ name: 'CountCompleted' });
      }
    },
    unresolvedCounts(products) {
    return products.filter((prod) => prod.counted !== prod.expected && prod.reason === '');
  },
    async fetchAllStockCount() {
      // fetch unfinished count
      const unfinished = GET_LOCAL_DB_DATA("save_for_later_prod") || [];
      
      await this.$store.dispatch("FETCH_ALL_STOCK_COUNT");
      const res = this.$store.getters["GET_ALL_STOCK_COUNTS"];

      if (res.status) {
        const counts = res.data || [];
        this.allCounts = counts.concat(unfinished).sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
      } else {
        // alert(res.message || res.error);
        eventBus.$emit('trac-alert', { message: res.message || res.error });
      }
    },
  },
};
</script>

<style></style>
